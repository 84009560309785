import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };
  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='AFFORDABLE INSURANCE IN MISSOURI' activeCard='missouri' image={'/Michigan_BG.jpeg'}>
          <p>
            We work hard to find the best insurance in Missouri for the lowest cost. Our agents have the experience and expertise to meet the needs of some of
            the most difficult health insurance parameters to deal with.
          </p>
          <p>
            Apollo agents start every potential client interface the same way starting with specific questions which provide insight into how they use
            healthcare. We identify whether you are in need of individual insurance, group insurance, life insurance or Medicare supplemental insurance. Areas
            we cover are preferred doctors the consumer would like to keep as an in-network provider, prescription usage and frequency of doctor visits. We work
            around the clock to make sure our clients in Missouri have affordable options.
          </p>
          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance in Michigan shifting to the consumer, gap insurance plans have become the most important and popular plan
            we sell today.
          </p>

          <p>
            We assist consumers in Missouri with health insurance, life insurance, dental plans, financial planning, and Medicare solutions. Apollo advisors
            also help small businesses with affordable group insurance options. In the individual market, we provide options both on and off-exchange. We
            partner and are appointed with all health insurance companies offering products in Missouri including Blue Cross & Blue Shield, Anthem, Aetna,
            Cigna, TransAmerica, and several off-exchange carriers.
          </p>

          <span>UNDERSTANDING THE CHANGES</span>

          <p>
            Since this industry changes rapidly, it makes it nearly impossible for consumers to keep up and understand what changes are taking place. We are
            experts in health insurance and can answer any questions you have about health insurance plans this year. Let us help you with getting your Missouri
            health insurance today! Challenges in the industry serve to fuel our passion for identifying the best solutions for consumers. Our advisors excel in
            educating our clients with understanding the fundamentals of health insurance and how their benefits work. The challenges for consumers in the
            industry emphasize how important Apollo’s trusted advisors are for 2018. We look forward to assisting you with the challenges evident today and with
            the new challenges that await.
          </p>
        </Content>
      </div>
    </Layout>
  );
};
